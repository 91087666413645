@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  max-width: 1200px;
  margin: 0 auto;
}

#site-name,
h1,
h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}

#curbulator-content {
  @apply mx-auto mb-20 px-5;
}

.old-model,
.new-model {
  min-height: 168px;
}

#bottom-menu {
  margin: 0 auto;
  .menu-item {
    @apply px-6 py-4 border-r border-zinc-300 items-center cursor-pointer;
  }
}

.box {
  @apply flex-col p-5 mb-4 text-center bg-stone-200 shadow-md shadow-stone-400 rounded;
}

.input-text {
  @apply w-full p-2 rounded ring-2 ring-stone-300 focus:ring-blue-300 focus:ring-2 focus:outline-none;
}

.btn-primary {
  @apply py-2 font-semibold text-white rounded shadow-md px-7 bg-sky-600 hover:bg-sky-500 focus:outline-none;
}

.btn-secondary {
  @apply py-2 font-semibold bg-white border rounded shadow-md px-7 border-stone-200 hover:bg-stone-100 focus:outline-none;
}

.btn-warning {
  @apply relative py-2 font-semibold text-white rounded shadow-md px-7 bg-amber-500 hover:bg-amber-400 focus:outline-none;
}

.btn-success {
  @apply relative py-2 font-semibold text-white rounded shadow-md px-7 bg-emerald-500 hover:bg-emerald-400 focus:outline-none;
}

.btn-danger {
  @apply relative py-2 font-semibold text-white bg-red-500 rounded shadow px-7 hover:bg-red-400 focus:outline-none;
}

.heading1 {
  @apply mb-4 font-extrabold uppercase lg:text-3xl md:text-4xl sm:text-3xl;
}

button:disabled {
  @apply cursor-not-allowed bg-stone-300 hover:bg-stone-300;
}

.roof-curb-btn {
  @apply w-4/5 p-3 font-semibold mx-auto uppercase hover:bg-stone-300 rounded;
}

.filled-box {
  @apply text-white bg-blue-900;
}

.curb-adapter {
  @apply relative bg-none text-center p-5 mb-4;
  min-height: 115px;
  filter: drop-shadow(0px 4px 3px #a8a29e);

  &::before {
    @apply bg-stone-200 absolute top-0 right-0 bottom-0 left-0 -z-10 rounded;
    --p: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
    -webkit-clip-path: var(--p);
    clip-path: var(--p);
    content: "";
  }
}

.mfield {
  max-width: 80px;
}

.supply-side {
  position: absolute;
  top: 0;
  padding: 10px 0;
  right: 5px;
  height: 85%;
  cursor: pointer;
}

input[type="text"]:disabled,
input[type="checkbox"]:disabled {
  background-color: #eee;
}

.search-icon {
  position: absolute;
  right: 14px;
  top: 6px;
}

.curb-search-item {
  @apply px-5 py-2 hover:bg-gray-100;
  border-bottom: 1px solid #eee;
}
.curb-search-item:hover {
  cursor: pointer;
}

div[class^="field-"] input[type="text"] {
  padding: 2px;
}

.loading-icon {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.loading-icon-search {
  position: absolute;
  right: 5px;
}
.loading-icon-search div {
  width: 5px !important;
  height: 5px !important;
}

input[type="checkbox"] {
  @apply focus:outline-none;
}

#m-side input[type="text"],
#s-side input[type="text"],
#c-side input[type="text"] {
  width: 100%;
  text-align: center;
}
.measurement-container {
  max-width: 650px;
}
input.switch-toggle:checked ~ .dot {
  transform: translateX(100%);
  background-color: #0284c7;
}
.m-label {
  @apply p-2 font-semibold border bg-stone-100;
  width: 30%;
}
.m-value {
  @apply p-2 border;
  width: 70%;
}
